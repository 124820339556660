import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/CallMeService/herosection.js";
import Services from "components/CallMeService/services.js";
import MainFeature from "components/features/TwoColWithButton.js";
import WhyUs from "components/CallMeService/whyUs.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Testimonial from "components/CallMeService/reviews.js";
import FAQ from "components/CallMeService/faq.js";
import Footer from "components/CallMeService/footer.js";
import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
import inpostImageSrc from "images/inpost.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-yellow-400`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Services
        subheading={<Subheading>Servizi</Subheading>}
        heading={
          <>
            Scopri la comodità di gestire le tue necessità quotidiane con la nostra <HighlightedText>gamma completa di servizi</HighlightedText>.
          </>
        }
      />
      <FeatureWithSteps
        subheading={<Subheading>INPOST</Subheading>}
        heading={
          <>
            Al Servizio del Cittadino! <br></br>
            <HighlightedText>INPOST Locker</HighlightedText> <br></br>
            Servizio di spedizione.
          </>
        }
        textOnLeft={false}
        imageSrc={inpostImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <Testimonial
        subheading={<Subheading>Cosa dicono i nostri clienti</Subheading>}
        heading={
          <>
            I nostri clienti <HighlightedText>ci amano</HighlightedText>.
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://lh3.googleusercontent.com/a-/ALV-UjUn5fNMn3e3uJg5C6Ntg2xWjtx2bkxAkZDXnSDjKOwD-UTsLM_o=w45-h45-p-rp-mo-br100",
            heading: "Servizio impeccabile!",
            quote:
              "Agenzia plurisevizi specializzata in spedizioni nazionali e internazionali. Elevata qualità del servizio e assistenza clienti anche nel post vendita. Prezzi competitivi. Si serve dei migliori spedizionieri su scala internazionale. Ho effettuato diverse spedizioni senza mai riscontrare alcun tipo di problema. Ho testato anche il servizio di ritiro pacchi a domicilio che è molto utile. A mio parere è la migliore agenzia di spedizioni nella Città di Crotone e forse anche della provincia. 🔝🔝🔝🔝🔝🔝🔝🔝🔝",
            customerName: "Gennaro De Donato",
            customerTitle: "Cliente"
          },
          {
            stars: 5,
            profileImageSrc:
              "https://lh3.googleusercontent.com/a/ACg8ocKaTdG3wn0j8OtwBoxpCV2mp046BLx4APDmth7pjiy9jFslcQ=w45-h45-p-rp-mo-br100",
            heading: "Competenti e Cordiali!",
            quote:
              "Esperienza molto positiva,personale cordiale e competente.Agenzia multiservizi super consigliata 👍🏻",
            customerName: "Caterina Urso",
            customerTitle: "Cliente"
          },
          {
            stars: 5,
            profileImageSrc:
              "https://lh3.googleusercontent.com/a/ACg8ocJ1DTTiNXdOcI2gqByoiT0_3HSB9KbSAvI9y1J166e-HicJ9w=w45-h45-p-rp-mo-br100",
            heading: "Professionali e Puntuali!",
            quote:
              "Agenzia di altissima qualità spedizioni  veloci e puntuali molto professionale ed accogliente il Titolare 5 stelle",
            customerName: "Romeo Imbra",
            customerTitle: "Cliente"
          },
          {
            stars: 5,
            profileImageSrc:
              "https://lh3.googleusercontent.com/a/ACg8ocLfVAt6MfzE7Y1-8xLgYmscBwes5xfKe3qsbbJMpZSlit5iIg=w45-h45-p-rp-mo-br100",
            heading: "Ottimo Servizio!",
            quote:
              "Ottimo servizio con prezzi molto convenienti personale  serio, massima educazione ma soprattutto simpaticissimi",
            customerName: "Emanuela Emy",
            customerTitle: "Cliente"
          },
          {
            stars: 5,
            profileImageSrc:
              "https://lh3.googleusercontent.com/a/ACg8ocJs2R-X_vLPdsi9-kTEmeUUCttfqBcVyFIcG2NkCRWbaf4cPw=w45-h45-p-rp-mo-br100",
            heading: "Ottimo Servizio!",
            quote:
              "Un'isola felice, in cui si trovano gentilezza, disponibilità e professionalità. Ho subito fatto amicizia con Pasquale e con la madre. Adorabili!",
            customerName: "Brunella Bonanno",
            customerTitle: "Cliente"
          },

        ]}
      />
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            <HighlightedText>Domande</HighlightedText> frequenti
          </>
        }
        faqs={[
          {
            question: "Quali sono gli orari di apertura?",
            answer:
              "Lunedì - Venerdì: 08:00 - 13:00 / 15:00 - 19:00.\n Domenica chiuso."
          },
          {
            question: "Quanto costa la spedizione di pacchi?",
            answer:
              "Il costo della spedizione dipende dal peso e dalla destinazione del pacco. Per avere un preventivo preciso, vi invitiamo a contattarci."
          },
          {
            question: "Posso spedire pacchi in tutto il mondo?",
            answer:
              "Si, offriamo servizi di spedizione nazionali e internazionali. Contattaci per maggiori informazioni."
          },
          {
            question: "Che succede se devo spedire un pacco internazionale?",
            answer:
              "Assolutamente nulla! Il nostro cliente non deve preoccuparsi di alcuna problematica, la gestione della dogana e delle spedizioni internazionali sono inclusi nel nostro servizio!"
          }
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
}
