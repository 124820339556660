import "slick-carousel/slick/slick.css";
import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import RecensioniIMG from "images/recensioni-google-scaled.webp";
import { ReactComponent as StarIconBase } from "images/star-icon.svg";
import { ReactComponent as ArrowLeftIcon } from "images/arrow-left-3-icon.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-3-icon.svg";

const Row = tw.div`flex flex-col md:flex-row justify-between items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 xl:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 xl:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:pr-12 lg:pr-16 md:order-first` : tw`md:pl-12 lg:pl-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
]);

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-6 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const TestimonialSlider = styled(Slider)`
  ${tw`w-full mt-10 text-center md:text-left`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;

const Testimonial = tw.div`outline-none h-full flex! flex-col`;
const StarsContainer = styled.div``;
const StarIcon = tw(StarIconBase)`inline-block w-5 h-5 text-orange-400 fill-current mr-1 last:mr-0`;
const TestimonialHeading = tw.div`mt-4 text-xl font-bold`;
const Quote = tw.blockquote`mt-4 mb-8 sm:mb-10 leading-relaxed font-medium text-gray-700`;

const CustomerInfoAndControlsContainer = tw.div`mt-auto flex justify-between items-center flex-col sm:flex-row`;

const CustomerInfo = tw.div`flex flex-col sm:flex-row items-center justify-center lg:justify-start`;
const CustomerProfilePicture = tw.img`rounded-full w-16 h-16 sm:w-20 sm:h-20`;
const CustomerTextInfo = tw.div`text-center md:text-left sm:ml-6 mt-2 sm:mt-0`;
const CustomerName = tw.h5`font-bold text-xl`;
const CustomerTitle = tw.p`font-medium text-secondary-100`;

const Controls = styled.div`
  ${tw`flex mt-8 sm:mt-0`}
  .divider {
    ${tw`my-3 border-r`}
  }
`;
const ControlButton = styled.button`
  ${tw`mx-3 p-4 rounded-full transition duration-300 bg-gray-200 hover:bg-gray-300 text-primary-500 hover:text-primary-700 focus:outline-none focus:shadow-outline`}
  svg {
    ${tw`w-4 h-4 stroke-3`}
  }
`;

export default ({
  imageSrc = RecensioniIMG,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  subheading = "Cosa dicono i nostri clienti",
  heading = "I nostri clienti ci amano",
  description = "Scopri le esperienze e le opinioni di chi ha già scelto i nostri servizi, direttamente dalle loro recensioni su Google. La soddisfazione dei nostri clienti è la nostra priorità e le loro parole sono una finestra aperta sulla qualità del nostro impegno.",
  textOnLeft = false,
  testimonials = [
    {
      stars: 5,
      profileImageSrc:
        "https://lh3.googleusercontent.com/a-/ALV-UjUn5fNMn3e3uJg5C6Ntg2xWjtx2bkxAkZDXnSDjKOwD-UTsLM_o=w45-h45-p-rp-mo-br100",
      heading: "Servizio impeccabile!",
      quote:
        "Agenzia plurisevizi specializzata in spedizioni nazionali e internazionali. Elevata qualità del servizio e assistenza clienti anche nel post vendita. Prezzi competitivi. Si serve dei migliori spedizionieri su scala internazionale. Ho effettuato diverse spedizioni senza mai riscontrare alcun tipo di problema. Ho testato anche il servizio di ritiro pacchi a domicilio che è molto utile. A mio parere è la migliore agenzia di spedizioni nella Città di Crotone e forse anche della provincia. 🔝🔝🔝🔝🔝🔝🔝🔝🔝",
      customerName: "Gennaro De Donato",
      customerTitle: "Cliente"
    },
    {
      stars: 5,
      profileImageSrc:
        "https://lh3.googleusercontent.com/a/ACg8ocKaTdG3wn0j8OtwBoxpCV2mp046BLx4APDmth7pjiy9jFslcQ=w45-h45-p-rp-mo-br100",
      heading: "Competenti e Cordiali!",
      quote:
        "Esperienza molto positiva,personale cordiale e competente.Agenzia multiservizi super consigliata 👍🏻",
      customerName: "Caterina Urso",
      customerTitle: "Cliente"
    },
    {
      stars: 5,
      profileImageSrc:
        "https://lh3.googleusercontent.com/a/ACg8ocJ1DTTiNXdOcI2gqByoiT0_3HSB9KbSAvI9y1J166e-HicJ9w=w45-h45-p-rp-mo-br100",
      heading: "Professionali e Puntuali!",
      quote:
        "Agenzia di altissima qualità spedizioni  veloci e puntuali molto professionale ed accogliente il Titolare 5 stelle",
      customerName: "Romeo Imbra",
      customerTitle: "Cliente"
    },
    {
      stars: 5,
      profileImageSrc:
        "https://lh3.googleusercontent.com/a/ACg8ocLfVAt6MfzE7Y1-8xLgYmscBwes5xfKe3qsbbJMpZSlit5iIg=w45-h45-p-rp-mo-br100",
      heading: "Ottimo Servizio!",
      quote:
        "Ottimo servizio con prezzi molto convenienti personale  serio, massima educazione ma soprattutto simpaticissimi",
      customerName: "Emanuela Emy",
      customerTitle: "Cliente"
    },
    {
      stars: 5,
      profileImageSrc:
        "https://lh3.googleusercontent.com/a/ACg8ocJs2R-X_vLPdsi9-kTEmeUUCttfqBcVyFIcG2NkCRWbaf4cPw=w45-h45-p-rp-mo-br100",
      heading: "Ottimo Servizio!",
      quote:
        "Un'isola felice, in cui si trovano gentilezza, disponibilità e professionalità. Ho subito fatto amicizia con Pasquale e con la madre. Adorabili!",
      customerName: "Brunella Bonanno",
      customerTitle: "Cliente"
    },
  ]
}) => {
  const [sliderRef, setSliderRef] = useState(null);

  return (
    <Container>
      <ContentWithPaddingXl>
        <Row>
          <ImageColumn>
            <Image src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
          </ImageColumn>
          <TextColumn textOnLeft={textOnLeft}>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <TestimonialSlider arrows={false} ref={setSliderRef}>
              {testimonials.map((testimonial, index) => (
                <Testimonial key={index}>
                  <StarsContainer>
                    {Array.from({ length: testimonial.stars }).map((_,indexIcon) => (
                      <StarIcon key={indexIcon} />
                    ))}
                  </StarsContainer>
                  <TestimonialHeading>{testimonial.heading}</TestimonialHeading>
                  <Quote>{testimonial.quote}</Quote>
                  <CustomerInfoAndControlsContainer>
                    <CustomerInfo>
                      <CustomerProfilePicture src={testimonial.profileImageSrc} alt={testimonial.customerName} />
                      <CustomerTextInfo>
                        <CustomerName>{testimonial.customerName}</CustomerName>
                        <CustomerTitle>{testimonial.customerTitle}</CustomerTitle>
                      </CustomerTextInfo>
                    </CustomerInfo>
                    <Controls>
                      <ControlButton onClick={sliderRef?.slickPrev}>
                        <ArrowLeftIcon />
                      </ControlButton>
                      <div className="divider" />
                      <ControlButton onClick={sliderRef?.slickNext}>
                        <ArrowRightIcon />
                      </ControlButton>
                    </Controls>
                  </CustomerInfoAndControlsContainer>
                </Testimonial>
              ))}
            </TestimonialSlider>
          </TextColumn>
        </Row>
      </ContentWithPaddingXl>
    </Container>
  );
};
