import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "images/logo-call-yellow.svg";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";

const Container = tw.div`relative bg-primary-500 text-gray-100 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const FiveColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`px-4 sm:px-0 sm:w-1/3 md:w-auto mt-12`;

const ColumnHeading = tw.h5`uppercase font-bold`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:border-gray-100 pb-1 transition duration-300`;
const LinkD = tw.a`border-b-2 border-transparent hocus:border-gray-100 pb-1 transition duration-300 font-bold`;
const Divider = tw.div`my-16 border-b-2 border-primary-400 w-full`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-1/2`;

const ThreeColRow = tw.div`flex flex-wrap md:flex-nowrap items-center justify-center text-center md:justify-between`;
const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 md:mx-auto font-medium`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-400 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;



export default () => {
  return (
    <Container>
      <Content>
        <FiveColumns>
          <Column>
            <ColumnHeading>CallMeService</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x134048f2b705a079:0x8d7ce0ad5bcdd1c7?sa=X&ved=1t:8290&ictx=111">Via Louis Braille, 5, 88900 Crotone KR</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="tel:+390962908817">0962 908817</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="tel:+39335 660 0045">335 660 0045</Link>
              </LinkListItem>
            </LinkList>
          </Column>
        </FiveColumns>
        <Divider />
        <ThreeColRow>
          <LogoContainer>
            <LogoImg src={LogoImage} />
          </LogoContainer>
          <CopywrightNotice>© 2024 Sviluppato da <LinkD href="https://www.devoz.it/">DevOz.it</LinkD></CopywrightNotice>
          <SocialLinksContainer>
            <SocialLink href="https://www.facebook.com/p/Call-Me-Service-100063708512463/">
              <FacebookIcon />
            </SocialLink>
          </SocialLinksContainer>
        </ThreeColRow>
      </Content>
    </Container>
  );
};
